import React from 'react';

function Loading() {
    return (
        <div className="loading">
            <div/>
            <div/>
            <div/>
        </div>
    );
}

export default Loading;