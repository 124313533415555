import React from 'react';
import {motion} from "framer-motion";

function YoursPage({src, visible, side}) {
    const width = "30%";
    let left = '0';
    let right = '0';
    if (side === -1) {
        // Left
        left = `calc(${width} * -2.3)`;
    } else if (side === 1) {
        // Right
        right = `calc(${width} * -2.3)`;
    }
    return (
        <motion.img
            src={src}
            style={{
                position: "absolute",
                width: width,
                marginTop: "auto",
                marginBottom: "auto",
                top: 0,
                bottom: 0,
                marginLeft: "auto",
                marginRight: "auto",
                right: right,
                left: left,
                borderRadius: "2vh",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
            initial={{
                opacity: 0,
                scale: 0.5,
            }}
            animate={{
                opacity: visible ? 1 : 0,
                scale: visible ? 1.0 : 0.3,
            }}
            transition={{
                duration: 0.6,
                ease: "easeInOut",
            }}
        />
    );
}

export default YoursPage;