import React from 'react';
import {motion} from "framer-motion"

function SectionTitle({children}) {
    return (
        <motion.h2
            initial={{opacity: 0}}
            whileInView={{ opacity: 1.0 }}
            transition={{duration: 0.5, ease: "easeInOut", delay: 0.3}}
        >{children}</motion.h2>
    );
}

export default SectionTitle;