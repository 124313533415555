import React from 'react';
import check from "../../../assets/check.svg";

function CheckItem({title}) {
    return (
        <div
            style={{
                alignSelf: "stretch",
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 4,
                paddingBottom: 4,
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 16,
                display: "inline-flex"
            }}
        >
            <div
                style={{
                    width: 32,
                    height: 32,
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexShrink: 0,
                }}
            >
                <img
                    style={{width: "90%", height: "90%"}}
                    src={check}
                    alt="Check"/>
            </div>
            <div
                style={{
                    color: "#1B1B1F",
                    fontSize: 18,
                    fontFamily: "Satoshi",
                    fontWeight: 500,
                    letterSpacing: "0.10px",
                    wordWrap: "break-word"
                }}
            >
                {title}
            </div>
        </div>

    );
}

export default CheckItem;