import React from 'react';
import {motion} from "framer-motion";

function SectionSubtitle({children}) {
    return (
        <motion.p
            style={{
                whiteSpace: "pre-line",
            }}
            initial={{opacity: 0}}
            whileInView={{opacity: 1.0}}
            transition={{duration: 0.5, ease: "easeInOut", delay: 0.6}}
        >{children}</motion.p>
    );
}

export default SectionSubtitle;