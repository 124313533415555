import {useLayoutEffect, useRef} from "react";

const useStickyCenter = (callback, disableSticky = false) => {
    const relativeDiv = useRef();
    const stickyDiv = useRef();

    useLayoutEffect(() => {
        const onScroll = () => {
            const relativeRect = relativeDiv.current.getBoundingClientRect();
            const rect = stickyDiv.current.getBoundingClientRect();

            // Calculate the top position so the sticky div will be centered.
            const centerTop = (window.innerHeight - relativeRect.height) / 2;

            const shouldSticky = relativeRect.top - centerTop <= 0;
            if (!disableSticky && shouldSticky) {
                // User scrolled enough so the sticky div is centered.
                stickyDiv.current.style.position = "fixed";
                stickyDiv.current.style.top = `${centerTop}px`;
                callback && callback(rect, true, shouldSticky);
            } else {
                // Sticky div is still below the center.
                stickyDiv.current.style.position = "relative";
                stickyDiv.current.style.top = null;
                callback && callback(rect, false, shouldSticky);
            }
        };
        onScroll();
        window.addEventListener("scroll", onScroll);
        window.addEventListener("resize", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("resize", onScroll);
        }
    }, [callback, disableSticky]);

    return [relativeDiv, stickyDiv];
}

export default useStickyCenter;