function Price({price}) {
    return (<>
        <div
            className="price-div"
            style={{
                paddingBottom: 16,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
        >
            <div
                className="current-price"
                style={{
                    color: "#1B1B1F",
                    fontSize: 50,
                    fontFamily: "Satoshi",
                    fontWeight: 900,
                    letterSpacing: "0.10px",
                    wordWrap: "break-word"
                }}
            >
                {price['current'].toFixed(2) + ' ' + price['currency']}
            </div>
            <div
                className="per-comp"
                style={{
                    color: "#1B1B1F",
                    fontSize: 22,
                    fontFamily: "Satoshi",
                    fontWeight: 700,
                    lineHeight: "12.99px",
                    letterSpacing: "0.10px",
                    wordWrap: "break-word"
                }}
            >
                {'per competitor'}
            </div>
        </div>
        <div
            className="discount-div"
            style={{
                justifyContent: "center", alignItems: "baseline", gap: 16,
                display: "inline-flex",
            }}
        >
            <div
                className="regular-price"
                style={{
                    color: "#1B1B1F",
                    fontSize: 24,
                    fontFamily: "Satoshi",
                    fontWeight: 900,
                    textDecoration: "line-through",
                    letterSpacing: "0.10px",
                    wordWrap: "break-word"
                }}
            >
                {price['regular'].toFixed(2) + ' ' + price['currency']}
            </div>
            <div
                style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                    background: "#FFDAD5",
                    borderRadius: 24,
                    justifyContent: "center",
                    alignItems: "flex-end",
                    display: "flex"
                }}
            >
                <div
                    className="discount"
                    style={{
                        color: "#1B1B1F",
                        fontSize: 26,
                        fontFamily: "Satoshi",
                        fontWeight: 900,
                        letterSpacing: "0.10px",
                        wordWrap: "break-word"
                    }}
                >
                    {'-' + price['discount'] + '%'}
                </div>
            </div>
        </div>
    </>);
}

export default Price;