import React, {useEffect, useState} from 'react';
import Background from "../../core/Background";
import {motion} from "framer-motion"

function LandingBackground({switchRef}) {
    const [shapesVisible, setShapesVisible] = useState(true);


    useEffect(() => {
        const handleScroll = () => {
            if (switchRef && switchRef.current) {
                const switchRefTop = switchRef.current.getBoundingClientRect().top;
                if (switchRefTop < 0) {
                    setShapesVisible(false);
                } else {
                    setShapesVisible(true);
                }
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [switchRef]);


    return (
        <>
            <motion.div
                style={{
                    zIndex: -100,
                    position: "absolute",
                }}
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: shapesVisible ? 1 : 0,
                }}>
                <Background/>
            </motion.div>
            <motion.div
                style={{
                    position: "fixed",
                    zIndex: -100,
                    top: 0,
                    bottom: 0,
                    width: "100vw",
                }}
                initial={{
                    backgroundColor: "white",
                }}
                animate={{
                    backgroundColor: shapesVisible ? "white" : "#f1f2fc",
                }}>
            </motion.div>
        </>
    );
}

export default LandingBackground;