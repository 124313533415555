import React, {useRef} from 'react';
import useScrollTriggeredCountUp, {easeOutExpo} from "../../hooks/use_scroll_triggered_count_up";

const Counter = ({value, duration = 1000, curve = easeOutExpo}) => {
    const ref = useRef(null);
    const count = useScrollTriggeredCountUp(ref, value, duration, curve);
    const textCount = count.toLocaleString();
    return <span ref={ref}>{textCount}</span>;
};

export default Counter;
