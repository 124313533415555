import React from 'react';

function NoPrice(props) {
    return (
        <div
            className="price-div"
            style={{
                paddingTop: 48,
                paddingBottom: 48,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
        >
            <div
                className="per-comp"
                style={{
                    color: "#1B1B1F",
                    fontSize: 24,
                    fontFamily: "Satoshi",
                    fontWeight: 700,
                    lineHeight: "12.99px",
                    letterSpacing: "0.10px",
                    wordWrap: "break-word"
                }}
            >
                Contact us for pricing
            </div>
        </div>
    );
}

export default NoPrice;