import React from 'react';
import logo from "../../assets/logo.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-section section-gap-half">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 footer-left">
                        <a href="/">
                            <img src={logo} alt="" height="50px"/>
                        </a>
                        <p className="copyright-text">
                            © {currentYear} Judo Mobile
                            <br/>
                            <a href="https://dopetheme.com" target="_blank" rel="noreferrer">
                                Created based on Dope Theme
                            </a>
                        </p>
                    </div>
                    <div className="col-lg-7">
                        <ul id="social">
                            <li>
                                <a href="mailto:contact@judomobile.app" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faEnvelope} size="2x"/>
                                </a>
                            </li>
                        </ul>
                        <ul className="footer-menu">
                            <li>
                                <a href="/pricing">Pricing</a>
                            </li>
                            <li>
                                <a href="https://docs.judomobile.app/">Documentation</a>
                            </li>
                            <li>
                                <a href="https://console.judomobile.app/">Judo Mobile Console</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;