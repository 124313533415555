import React, {useRef, useState} from 'react';
import yoursOnList from "../../../../../assets/yours/YoursOnList.mp4";
import yoursMatchesPage from "../../../../../assets/yours/YoursMatchesPage.webp";
import yourCompPage from "../../../../../assets/yours/YourCompPage.webp";
import yoursPage from "../../../../../assets/yours/YoursPage.webp";
import {motion} from "framer-motion"
import YoursPage from "./YoursPage";

function YoursAnim() {
    const videoRef = useRef();
    const [screensVisible, setScreensVisible] = useState(false);

    const startAnim = () => {
        if (videoRef.current) {
            // Start the video after 1.5 seconds
            setTimeout(() => {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
            }, 1500);

            // Show yours screens after the video ends
            setTimeout(() => {
                setScreensVisible(true);
            }, 3500);
        }
    };

    const resetAnim = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setScreensVisible(false);
        }
    }

    return (
        <div style={{
            position: "relative",
        }}>
            <motion.div
                initial={{
                    opacity: 1,
                    scale: 1.0,
                }}
                animate={{
                    opacity: screensVisible ? 0.5 : 1,
                    scale: screensVisible ? 0.8 : 1.0,
                }}
                transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                }}
            >
                <motion.video
                    ref={videoRef}
                    style={{
                        height: "70svh",
                        maxWidth: "100%",
                    }}
                    src={yoursOnList}
                    muted
                    playsInline
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1.0}}
                    onViewportEnter={startAnim}
                    onViewportLeave={resetAnim}
                    transition={{duration: 0.5, ease: "easeInOut", delay: 0.9}}/>
            </motion.div>

            <YoursPage src={yoursMatchesPage} visible={screensVisible} side={-1}/>
            <YoursPage src={yourCompPage} visible={screensVisible} side={0}/>
            <YoursPage src={yoursPage} visible={screensVisible} side={1}/>
        </div>
    );
}

export default YoursAnim;