import React from 'react';
import judoShiai from "../../../assets/judoshiai.png";
import judoManager from "../../../assets/judo_manager.svg";
import ClickableCard from "../../core/ClickableCard";

function ChooseSystem({setSystem}) {
    return (<div style={{
        display: 'flex', alignItems: 'center', height: 400, fontFamily: "Roboto", fontWeight: 'normal', color: '#000d',
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            paddingBottom: 32,
            paddingLeft: 16,
            paddingRight: 16,
        }}>
            <div style={{
                textAlign: 'center',
                paddingBottom: 48,
                color: "#1B1B1F",
                fontSize: 24,
                fontFamily: "Satoshi",
                fontWeight: 700,
                letterSpacing: "0.10px",
            }}>
                Select tournament system
            </div>
            <ClickableCard onClick={() => setSystem('manager')}>
                <div style={{height: 50, display: "flex", alignItems: 'center'}}>
                    <img style={{maxWidth: 200, width: '100%', padding: '0px 16px'}} src={judoManager}
                         alt="Judo Manager"/>
                </div>
            </ClickableCard>

            <div style={{fontSize: 22, textAlign: 'center', padding: 12}}>
                or
            </div>

            <ClickableCard onClick={() => setSystem('shiai')}>
                <div
                    style={{fontSize: 26, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50}}>
                    <img style={{height: 40, paddingRight: 16}} src={judoShiai} alt="JudoShiai icon"/>
                    JudoShiai
                </div>
            </ClickableCard>
        </div>
    </div>);
}

export default ChooseSystem;