import {useEffect} from 'react';

function Page({child, title}) {
    useEffect(() => {
        if (title) {
            document.title = title;

        }
    }, [title]);
    return child;
}

export default Page;