import React, {useEffect, useState} from 'react';
import logo from '../../assets/logo.svg';
import {motion} from "framer-motion"

const NavBar = () => {
    const [navActive, setNavActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [breakpointOn, setBreakpointOn] = useState(false);
    const [stickyNav, setStickyNav] = useState(false);
    const [navVisible, setNavVisible] = useState(true);
    const [y, setY] = useState(window.scrollY);

    useEffect(() => {
        const breakpointCheck = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= 991) {
                setBreakpointOn(true);
            } else {
                setBreakpointOn(false);
            }
        };

        breakpointCheck();

        window.addEventListener('resize', breakpointCheck);

        const handleScroll = () => {
            // Make sticky when not at top
            if (window.scrollY > 0) {
                setStickyNav(true);
            } else {
                setStickyNav(false);
            }

            // Show nav when scrolled up
            if (window.scrollY < y) {
                // Scroll up
                setNavVisible(true);
            } else {
                // Scroll down
                setNavVisible(false);
            }
            setY(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [y]);

    const handleNavToggle = () => {
        setNavActive(!navActive);
        setMenuActive(!menuActive);
    };

    const handleCloseIconClick = () => {
        setMenuActive(false);
        setNavActive(false);
    };

    return (
        <motion.header
            className={`header-area`}
            animate={{
                y: navVisible ? 0 : -100,
            }}
            transition={{
                ease: "easeInOut",
            }}>
            <div
                className={`dope-nav-container left ${breakpointOn ? 'breakpoint-on' : 'breakpoint-off'} ${stickyNav ? 'dope-sticky' : ''}`}>
                <div className="container">
                    <div className="row">
                        <nav className="dope-navbar justify-content-between" id="dopeNav">
                            <a className="nav-brand" href="/">
                                <img src={logo} alt="Judo Mobile" height="50px"/>
                            </a>
                            <div className="dope-navbar-toggler" onClick={handleNavToggle}>
                <span className={`navbarToggler ${navActive ? 'active' : ''}`}>
                  <span/>
                  <span/>
                  <span/>
                </span>
                            </div>
                            <div className={`dope-menu ${menuActive ? 'menu-on' : ''}`}>
                                <div className="dopecloseIcon" onClick={handleCloseIconClick}>
                                    <div className="cross-wrap">
                                        <span className="top"/>
                                        <span className="bottom"/>
                                    </div>
                                </div>
                                <div className="dopenav">
                                    <ul id="nav">
                                        <li>
                                            <a href="/">Home</a>
                                        </li>
                                        <li>
                                            <a href="./pricing">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="https://docs.judomobile.app/">Documentation</a>
                                        </li>
                                        <li>
                                            <a href="https://console.judomobile.app/">Console</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </motion.header>
    );
};

export default NavBar;
