import React from 'react';

function FreeBetaPrice() {
    return (
        <div
            className="price-div"
            style={{
                paddingTop: 24,
                paddingBottom: 16,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
        >
            {/*<div*/}
            {/*    className="per-comp"*/}
            {/*    style={{*/}
            {/*        color: "#1B1B1F",*/}
            {/*        fontSize: 24,*/}
            {/*        fontFamily: "Satoshi",*/}
            {/*        fontWeight: 700,*/}
            {/*        lineHeight: "12.99px",*/}
            {/*        letterSpacing: "0.10px",*/}
            {/*        wordWrap: "break-word"*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Contact us for pricing*/}
            {/*</div>*/}
            <div
                className="per-comp"
                style={{
                    color: "#1B1B1F",
                    fontSize: 22,
                    fontFamily: "Satoshi",
                    fontWeight: 700,
                    lineHeight: "12.99px",
                    letterSpacing: "0.10px",
                    wordWrap: "break-word"
                }}
            >
                Free till the end of 2024
            </div>
            <div
                className="per-comp"
                style={{
                    color: "#858585",
                    fontSize: 16,
                    fontFamily: "Satoshi",
                    fontWeight: 500,
                    wordWrap: "break-word",
                    paddingTop: 24,
                }}
            >
                Contact us for more details
            </div>
        </div>
    );
}

export default FreeBetaPrice;