import React from 'react';
import CheckItem from "./CheckItem";
import FreeBetaPrice from "./FreeBetaPrice";

function ManagerPricing(props) {
    return (<div
        style={{
            paddingBottom: 8,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 8,
            display: "inline-flex"
        }}
    >
        <FreeBetaPrice/>
        <div
            style={{
                alignSelf: "stretch",
                height: 17,
                padding: "8px 32px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex"
            }}
        >
            <div
                style={{
                    alignSelf: "stretch", height: 0, borderBottom: "1px #C5C6D0 solid"
                }}
            />
        </div>
        <div
            style={{
                alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "flex",
                padding: "0 16px"
            }}
        >
            <CheckItem title="Realtime tournament results"/>
            <CheckItem title="In-app live streams"/>
            <CheckItem title="Replay matches anytime"/>
            <CheckItem title="Notifications about upcoming matches"/>
            <CheckItem title="Access to tournament statistics"/>
            <div
                style={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 10,
                    display: "flex"
                }}
            >
                <div
                    style={{
                        background: "#0258C9",
                        borderRadius: 100,
                        overflow: "hidden",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        display: "flex"
                    }}
                >
                    <div
                        style={{
                            alignSelf: "stretch",
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 8,
                            paddingBottom: 8,
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 8,
                            display: "inline-flex"
                        }}
                    >
                        <a
                            style={{
                                textAlign: "center",
                                color: "white",
                                fontSize: 20,
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                lineHeight: "32px",
                                wordWrap: "break-word",
                                textDecoration: "none"
                            }}
                            href="mailto:contact@judomobile.app"
                        >
                            Contact us
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            className="country-note"
            style={{
                paddingRight: 32,
                paddingLeft: 32,
                paddingBottom: 8,
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 4,
                display: "inline-flex"
            }}
        >
            <div
                style={{
                    letterSpacing: "0.10px",
                    wordWrap: "break-word",
                    fontWeight: 400,
                    textAlign: "center",
                }}
            >
                Price for tournaments organized
                <br/>using JudoManager software.
            </div>
        </div>
    </div>);
}

export default ManagerPricing;