import React, {useRef} from 'react';
import ScrollTop from "../../core/ScrollTop";
import Footer from "../../core/Footer";
import StatsSection from "./sections/StatsSection";
import BannerSection from "./sections/banner/BannerSection";
import EnterJudoSection from "./sections/enter_judo/EnterJudoSection";
import YoursSection from "./sections/yours/YoursSection";
import LandingBackground from "./LandingBackground";

function Landing() {
    const enterJudoRef = useRef();

    return (
        <>
            <BannerSection/>
            <EnterJudoSection ref={enterJudoRef}/>
            <YoursSection/>
            <StatsSection/>
            {/* Start organizer get started section */}
            <section
                className="get-started-section"
                id="download-section"
            >
                <div className="container">
                    <div className="row m-auto get-started-wrap justify-items-between align-items-center">
                        <div className="col-lg-6 titles">
                            <h1>Are you an organizer?</h1>
                            <h4>
                                Checkout our comprehensive documentation
                                <br/>
                                and improve your tournament experience.
                            </h4>
                        </div>
                        <div className="col-lg-6 buttons">
                            <a
                                href="https://docs.judomobile.app/docs/get_started"
                                className="primary-btn"
                            >
                                <span>Get started as organizer</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* End organizer get started section */}

            <Footer/>
            <LandingBackground switchRef={enterJudoRef}/>
            <ScrollTop/>
        </>
    );
}

export default Landing;