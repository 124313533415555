import React from 'react';
import AutoCounter from "../../../core/AutoCounter";

function StatsSection(props) {
    const appInstalls = {
        value: 61100,
        date: "2024-08-26",
        monthlyIncrease: 1500,
        round: 100,
        showPlus: false,
    };
    const tournaments = {
        value: 392,
        date: "2024-08-26",
        monthlyIncrease: 4,
        round: 10,
        showPlus: false,
    };
    const countries = {
        value: 17,
        date: "2024-08-26",
        monthlyIncrease: null,
        round: 1,
        showPlus: false,
    };
    const competitors = {
        value: 94200,
        date: "2024-08-26",
        monthlyIncrease: 1600,
        round: 100,
        showPlus: false,
    };

    return (
        <section className="stat-section section-gap-half">
            <div className="container-fluid stat-card">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-stat">
                                <span className="material-symbols-outlined">download_for_offline</span>
                                <AutoCounter {...appInstalls}/>
                                <h4>App installs</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-stat">
                                <span className="material-symbols-outlined">event</span>
                                <AutoCounter {...tournaments}/>
                                <h4>Tournaments</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-stat">
                                <span className="material-symbols-outlined">public</span>
                                <AutoCounter {...countries}/>
                                <h4>Countries</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-stat">
                                <span className="material-symbols-outlined">group</span>
                                <AutoCounter {...competitors}/>
                                <h4>Competitors</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default StatsSection;