import React, {useEffect, useState} from 'react';
import CountrySelect from "./CountrySelect";
import CheckItem from "./CheckItem";
import Loading from "../../core/Loading";
import Price from "./Price";
import NoPrice from "./NoPrice";

function ShiaiPricing({countryCode, isDefaultCountryCode}) {
    const [price, setPrice] = useState(null);
    const [noPrice, setNoPrice] = useState(false);

    useEffect(() => {
        const analytics = async () => {
            await fetch('https://server307297.nazwa.pl/jm_results/analytics.php?c=' + countryCode)
                .catch(error => {
                    console.error("Error fetching analytics data:", error);
                });
        }

        const downloadPricing = async () => {
            const response = await fetch('/pricing.json?q=' + Date.now());
            return await response.json();
        }

        const loadPricing = async () => {
            if (!isDefaultCountryCode) {
                await analytics();
            }

            const pricing = await downloadPricing();

            if (countryCode) {
                const countryPrice = pricing[countryCode];
                if (countryPrice) {
                    setPrice(countryPrice);
                    setNoPrice(false);
                } else {
                    console.error("Country code not found in pricing data");
                    setPrice(null);
                    setNoPrice(true);
                }
            }
        }

        loadPricing();
    }, [countryCode, isDefaultCountryCode]);

    const loading = price === null && !noPrice;

    return (<div
        style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 8,
            display: "inline-flex"
        }}
    >
        {loading && <Loading/>}
        {(!loading && !noPrice) && <Price price={price}/>}
        {(!loading && noPrice) && <NoPrice/>}
        <div
            style={{
                alignSelf: "stretch",
                height: 17,
                padding: "8px 32px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex"
            }}
        >
            <div
                style={{
                    alignSelf: "stretch", height: 0, borderBottom: "1px #C5C6D0 solid"
                }}
            />
        </div>
        <div
            style={{
                alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "flex",
                padding: "0 16px"
            }}
        >
            <CheckItem title="All Judo Mobile features"/>
            <CheckItem title="Realtime mode for all users"/>
            <CheckItem title="In-app registration system (beta)"/>
            <CheckItem title="Access to tournament statistics"/>
            <CheckItem title="Direct support and fast replies"/>
            <div
                style={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 10,
                    display: "flex"
                }}
            >
                <div
                    style={{
                        background: "#0258C9",
                        borderRadius: 100,
                        overflow: "hidden",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        display: "flex"
                    }}
                >
                    <div
                        style={{
                            alignSelf: "stretch",
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 8,
                            paddingBottom: 8,
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 8,
                            display: "inline-flex"
                        }}
                    >
                        <a
                            style={{
                                textAlign: "center",
                                color: "white",
                                fontSize: 20,
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                lineHeight: "32px",
                                wordWrap: "break-word",
                                textDecoration: "none"
                            }}
                            href="https://docs.judomobile.app"
                        >
                            Get started
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            className="country-note"
            style={{
                paddingRight: 32,
                paddingLeft: 32,
                paddingBottom: 8,
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 4,
                display: "inline-flex"
            }}
        >
            <div
                style={{
                    letterSpacing: "0.10px",
                    wordWrap: "break-word",
                    textAlign: "center",
                    fontWeight: 400,
                }}
            >
                Price for tournaments organized using
                <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; JudoShiai software in
                <CountrySelect countryCode={countryCode}/>
            </div>
        </div>
    </div>);
}

export default ShiaiPricing;