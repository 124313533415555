import React from 'react';
import {Container} from "react-bootstrap";
import background from "../../../../../assets/enter_judo/background.webp";
import SectionTitle from "../../SectionTitle";
import SectionSubtitle from "../../SectionSubtitle";
import {motion} from "framer-motion"
import BackgroundImage from "../../../../core/BackgroundImage";
import Carousel from "./Carousel";

function EnterJudoSection(props, ref) {
    return (
        <section
            ref={ref}
            className="section-dark text-center"
        >
            <BackgroundImage src={background}/>
            <Container className="px-2">
                <SectionTitle>Enter the World of Judo</SectionTitle>
                <SectionSubtitle>
                    Take control over tournaments with one app.
                </SectionSubtitle>
                <motion.div
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1.0}}
                    transition={{duration: 0.5, ease: "easeInOut", delay: 0.9}}>
                    <Carousel parentRef={ref}/>
                </motion.div>
            </Container>
        </section>
    );
}

export default React.forwardRef(EnterJudoSection);