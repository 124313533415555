import React, {useEffect} from 'react';
import {motion} from "framer-motion"

function CarouselImage({src, index, length, selectedIndex, onSelected, height}) {
    const isSelected = index === selectedIndex;
    const isRightSelected = (index + 1) % length === selectedIndex;

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

    const width = Math.min(500, windowWidth * 0.8);

    const x = isSelected ? 0 : (isRightSelected ? -1 : 1) * windowWidth * 0.3;
    const y = isSelected ? 0 : -windowHeight * 0.2;
    const scale = isSelected ? 1.0 : 0.4;
    const opacity = isSelected ? 1.0 : 0.5;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <motion.img
            style={{
                position: "absolute",
                marginLeft: -width / 2,
                width: width,
                height: height,
                objectFit: "contain",
                cursor: isSelected ? null : "pointer",
                pointerEvents: isSelected ? "none" : null,
                zIndex: isSelected ? 1 : 0,
            }}
            animate={{
                x: x,
                y: y,
                scale: scale,
                opacity: opacity,
            }}
            transition={{
                ease: "easeInOut",
                duration: 0.8,
            }}
            onClick={onSelected}
            src={src}/>
    );
}

export default CarouselImage;