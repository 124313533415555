import React from 'react';
import '../../../pricing.css';
import Background from "../../core/Background";
import PricingCard from "./PricingCard";

function Pricing() {
    return (
        <div className="main" style={{width: "100%"}}>
            <div
                className="adaptive"
                style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "inline-flex"
                }}
            >
                <div className="slogan">
                    Simple pricing
                    <br/>
                    for tournaments
                    <br/>
                    of all sizes
                </div>
                <PricingCard/>
            </div>
            <Background/>
        </div>
    );
}

export default Pricing;