import React, {useEffect, useRef, useState} from 'react';
import SideSection from "../../SideSection";
import YoursAnim from "./YoursAnim";
import {useInView} from "framer-motion";

function YoursSection() {
    const underlineRef = useRef();
    const underlineInView = useInView(underlineRef);
    const [underlineVisible, setUnderlineVisible] = useState(false);

    useEffect(() => {
        if (underlineInView) {
            setTimeout(() => {
                setUnderlineVisible(true);
            }, 4000);
        } else {
            setUnderlineVisible(false);
        }
    }, [underlineInView]);

    return (
        <SideSection
            title={
                <>
                    Focus on
                    {" "}
                    <span ref={underlineRef} className="underline-primary relative" style={{
                        backgroundSize: underlineVisible ? "100% 0.5rem" : "0% 0.5rem",
                    }}>
                        Yours
                    </span>
                </>
            }
            subtitle={"Choose your favorite competitors,\nand we will highlight them for you.\nNo more searching!"}>
            <YoursAnim/>
        </SideSection>
    );
}

export default YoursSection;