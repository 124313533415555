import React from 'react';
import tournaments from "../../../../../assets/tournaments.webp";
import Phone from "../../Phone";
import useStickyCenter from "../../../../../hooks/useStickyCenter";
import {motion} from "framer-motion"

function FullscreenPhone({sectionRef, hideMe}) {
    const [x, setX] = React.useState(0);
    const [scale, setScale] = React.useState(1);
    const [visible, setVisible] = React.useState(true);
    const [hidden, setHidden] = React.useState(false);
    const [mounted, setMounted] = React.useState(true);
    const [afterAnim, setAfterAnim] = React.useState(false);
    const [relativeDiv, stickyDiv] = useStickyCenter((rect, sticky, shouldSticky) => {
        if (sticky) {
            setX((window.innerWidth - rect.width) / 2 - rect.x);
            setScale(window.innerWidth / rect.width * 1.1);
        } else {
            setMounted(true);
            setX(0);
            setScale(1);
        }
        setVisible(!sticky);

        const sectionRect = sectionRef.current?.getBoundingClientRect();
        if (window.scrollY > (sectionRect?.height ?? 0)) {
            // Completely unmount phone from the screen when the section is scrolled out.
            // This fixes ugly load when page is initially scrolled.
            hideMe(true);
            setMounted(false);
        }

        if (!shouldSticky) {
            // Reset the animation when the sticky div is below the center.
            setAfterAnim(false);
        }
    }, afterAnim);

    const onStart = () => {
        const forward = scale !== 1;
        if (forward && !hidden && mounted) {
            // Jump to the next section
            const rect = sectionRef.current.getBoundingClientRect();
            window.scrollTo({
                top: rect.height,
                behavior: "smooth",
            });
        }
    }

    const onComplete = () => {
        const forward = scale !== 1;
        hideMe(forward);
        setHidden(forward);
        if (forward) {
            // Wait for the animation to finish before marking animation as done.
            // It causes the phone to reset its position and makes it not sticky.
            setTimeout(() => {
                setAfterAnim(true);
            }, 1000);
        }
    }


    return (
        <div ref={relativeDiv} className="phone-size">
            <div ref={stickyDiv}>
                <motion.div
                    style={{
                        opacity: mounted ? 1 : 0,
                    }}
                    animate={{
                        x: x,
                        scale: scale,
                    }}
                    transition={{
                        duration: afterAnim ? 0 : 0.3,
                        ease: "circIn",
                    }}
                    onAnimationStart={onStart}
                    onAnimationComplete={onComplete}>
                    <motion.div
                        animate={{
                            opacity: visible ? 1.0 : 0,
                        }}
                        transition={{
                            times: [0, 1],
                            delay: 0.5,
                            duration: afterAnim ? 0 : 0.5,
                        }}>
                        <Phone src={tournaments} visible={visible}/>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
}

export default FullscreenPhone;