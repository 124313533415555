import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";

const ScrollTopButton = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 600) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

    return (
        <div
            className="scroll-top"
            onClick={scrollToTop}
            style={{opacity: visible ? '1' : '0'}}
        >
            <FontAwesomeIcon icon={faAngleUp} />
        </div>
    );
};

export default ScrollTopButton;
