import React from 'react';
import useSize from "@react-hook/size";
import {motion} from "framer-motion"

function Phone({src, visible = true}) {
    const target = React.useRef(null)
    const [, height] = useSize(target)

    const borderWidth = height * 0.017;
    const radius = height * 0.07;
    const frameStyle = {
        position: "absolute",
        borderWidth: borderWidth,
        borderRadius: radius,
    };

    return (
        <div className="phone-size" ref={target}>
            <div className="phone-frame" style={{
                borderRadius: radius,
                borderWidth: borderWidth,
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#FFF",
            }}/>
            <motion.img
                className="phone-frame"
                style={frameStyle}
                animate={{
                    opacity: visible ? 1.0 : 0,
                }}
                src={src}
                alt="Phone"/>
        </div>
    );
}

export default Phone;