import React from 'react';

function BackgroundImage({src}) {
    return (
        <div className="background-image-size">
        <div className="background-image-crop">
            <div className="background-image"
                 style={{background: `url(${src}) center center / cover no-repeat fixed`,}}/>

        </div>
        </div>
    );
}

export default BackgroundImage;