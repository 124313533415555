import './App.scss';
import './scss/style.scss';
import './scss/responsive.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Landing from "./components/pages/landing/Landing";
import Page from "./components/core/Page";
import Pricing from "./components/pages/pricing/Pricing";
import NavBar from "./components/core/NavBar";
import React from "react";


function App() {
    return (
        <BrowserRouter>
            <NavBar/>
            <Routes>
                <Route path="/" element={<Page title={"Judo Mobile - ultimate solution to follow judo tournaments."} child={<Landing/>}/>}/>
                <Route path="/pricing" element={<Page title={"Judo Mobile - Pricing"} child={<Pricing/>}/>}/>
                <Route path="*" element={<Page title={"Judo Mobile - ultimate solution to follow judo tournaments."} child={<Landing/>}/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
