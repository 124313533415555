import React from 'react';

function ClickableCard({children, onClick}) {
    return (
        <div className="clickable-card" onClick={onClick}>
            {children}
        </div>
    );
}

export default ClickableCard;