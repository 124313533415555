import React from 'react';
import appStore from '../../../../../assets/app_store.svg';
import {Col, Container, Row} from "react-bootstrap";
import FullscreenPhone from "./FullscreenPhone";

function BannerSection(props) {
    const sectionRef = React.useRef();
    const [hidePhone, setHidePhone] = React.useState(false);

    return (<section
        ref={sectionRef}
        className="banner-section"
        style={{
            zIndex: hidePhone ? -1 : 1,
        }}>
        <Container>
            <Row className="align-items-center justify-content-center m-auto">
                <Col md={6} className="banner-left">
                    <div style={{paddingLeft: 16}}>
                        <h1>Judo Mobile</h1>
                        <h2>
                            Ultimate solution to follow
                            <br/>
                            judo tournaments.
                        </h2>
                    </div>
                    <br/>
                    <a href="https://play.google.com/store/apps/details?id=pl.daniel.judo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            width="280px"
                        />
                    </a>
                    <a href="https://apps.apple.com/app/id1481008402">
                        <img
                            className="btn"
                            alt="Download on the App Store"
                            src={appStore}
                            width="250px"
                        />
                    </a>
                </Col>
                <Col md={6} className="banner-right d-flex justify-content-center">
                    <FullscreenPhone sectionRef={sectionRef} hideMe={setHidePhone}/>
                </Col>
            </Row>
        </Container>
    </section>);
}

export default BannerSection;