import React from 'react';
import Counter from "./Counter";
import {easeOutExpo} from "../../hooks/use_scroll_triggered_count_up";

function AutoCounter({value, date, monthlyIncrease, curve=easeOutExpo, round=1, showPlus=true}) {
    const now = new Date();
    const diff = now - new Date(date);
    const months = diff / (1000 * 60 * 60 * 24 * 30);
    const val = Math.floor(value + months * monthlyIncrease);
    const currentValue = val - (val % round);

    return (
        <h1>
            <Counter value={currentValue} curve={curve}/>{showPlus && "+"}
        </h1>
    );
}

export default AutoCounter;