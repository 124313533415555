import React, {useEffect, useState} from 'react';
import CarouselImage from "./CarouselImage";
import match from "../../../../../assets/enter_judo/MatchItemLive.webp";
import category from "../../../../../assets/enter_judo/CategoryDrawLive.png";
import competitors from "../../../../../assets/enter_judo/CompetitorsList.webp";
import useElementSize from "../../../../../hooks/useElementSize";
import {motion} from "framer-motion"

function Carousel({parentRef}) {
    const items = [
        {
            src: category,
            slogan: "Keep track of category draw",
        },
        {
            src: competitors,
            slogan: "Follow key competitors",
        },
        {
            src: match,
            slogan: "Stay updated on every fight",
        },
    ];
    const [autoPlay, setAutoPlay] = useState(true);
    const [index, setIndex] = useState(0);
    const [, height] = useElementSize(parentRef);

    // Change image every 5 seconds
    useEffect(() => {
        if (autoPlay) {
            const interval = setInterval(() => {
                setIndex((index + 1) % items.length);
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [autoPlay, index, items.length]);

    const manualIndex = (index) => {
        setAutoPlay(false);
        setIndex(index);
    }

    const imgHeight = height * 0.4;
    return (
        <div
            style={{
                position: "absolute",
                top: "45%",
                bottom: 32,
                left: 0,
                right: 0,
                height: imgHeight,
            }}>
            {items.map((item, i) => (
                <CarouselImage key={i}
                               src={item.src}
                               index={i}
                               length={items.length}
                               onSelected={() => manualIndex(i)}
                               height={imgHeight}
                               selectedIndex={index}/>
            ))}
            <motion.p
                key={items[index].slogan}
                className="secondary-text"
                style={{
                    position: "absolute",
                    top: "108%",
                    left: 0,
                    right: 0,
                }}
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                }}
                exit={{
                    opacity: 0,
                }}
                transition={{
                    ease: "easeInOut",
                    duration: 0.5,
                }}
            >
                {items[index].slogan}
            </motion.p>
        </div>
    );
}

export default Carousel;