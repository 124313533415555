import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import SectionTitle from "./SectionTitle";
import SectionSubtitle from "./SectionSubtitle";

function SideSection({title, subtitle, children}) {
    return (
        <section className="d-flex align-items-center">
            <Container>
                <Row className="align-items-center m-auto">
                    <Col md={6}>
                        <SectionTitle>{title}</SectionTitle>
                        <SectionSubtitle>{subtitle}</SectionSubtitle>
                    </Col>
                    <Col md={6} className="text-center">
                        {children}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SideSection;