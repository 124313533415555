import React from 'react';
import upperShape from "../../assets/upper_shape.svg";
import lowerShape from "../../assets/lower_shape.svg";

function Background() {
    return (
        <div className="jm-background">
            <img className="jm-background-upper" src={upperShape} alt=""/>
            <img className="jm-background-lower" src={lowerShape} alt=""/>
        </div>
    );
}

export default Background;